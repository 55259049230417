import { DEFAULT_DPI } from '@dtx-company/flowcode-generator-browser/src/generateFlowcode.constant'
import { DownloadFlowcodeType } from '../types/flowcode.types'
import { fileReaderDownload } from './flowcode'
import { generateFlowcode } from '@dtx-company/flowcode-generator-browser/src/generateFlowcode'
import { logger } from '@dtx-company/logger'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowcode'

// TODO - figure out why there are so many downloadFlowcode duplicates - DF
export const downloadFlowcode = async ({
  targetFileType,
  filename,
  flowcodeOptions,
  dpi = DEFAULT_DPI,
  flowcodeId,
  onDownload
}: DownloadFlowcodeType): Promise<void> => {
  return new Promise((resolve, reject) => {
    generateFlowcode(flowcodeOptions, dpi, targetFileType)
      .then(response => response.blob())
      .then(async blob => {
        await fileReaderDownload(filename || 'flowcode', targetFileType, blob)
        events.userDownloadedFlowcode(flowcodeId, targetFileType)
        onDownload?.()
        resolve()
      })
      .catch(e => {
        logger.logError(e, { technicalArea: 'authentication' })
        reject(e)
      })
  })
}
